import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../contexts/context';


import { makeStyles } from '@mui/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';


// Tools
import QRCode from 'qrcode.react'

const styles = (theme) => ({
  root: {
    margin: 0,
    //padding: theme.spacing(2),
    padding: 20,
  },
  closeButton: {
    position: 'absolute',
    //right: theme.spacing(1),
    //top: theme.spacing(1),
    right: 10,
    top: 10,
    color: "grey",
    color: theme.palette.grey[500],
  },

});

const useStyles = makeStyles(() => ({
  qrCode: {
    marginBottom: '20px'
  }
}));


/* const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}); */

/* const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))(MuiDialogContent); */

/* const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions); */

const OpenOnOtherDeviceDialog = () => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);
  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    if (state.uiDialog === 'appLink') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.uiDialog]);

  const handleCloseClick = () => {
    dispatch({type: 'app/ui/dialog/default/close'});
  }

  return (
    <div>
      <Dialog onClose={handleCloseClick} aria-labelledby="form-dialog-appLink" open={open}>
        <DialogTitle id="form-dialog-appLink" onClose={handleCloseClick}>
            Open on other Device
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Scan this QR code on a device with Android & Chrome browser.
          </Typography>
          <Typography style={{ margin: '15px'}} gutterBottom> </Typography>
          <QRCode className={classes.qrCode} size={256} value={state.currentURL} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseClick} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};

export default OpenOnOtherDeviceDialog;
