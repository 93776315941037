import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ContextProvider } from './contexts/context'


ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <App />
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

//Check if browser supports PWA
if('serviceWorker' in navigator){
  navigator.serviceWorker.register('/sw.js', {scope:'../'}).then(registration =>{
    console.log("Service Worker is Registered!")
    //console.log(registration)
    
  }).catch(err=>{
    console.log("Service Worker failure")
    //console.log(err)
  })
}else{
// Tell user Brower doesnt allow Service worker
  console.log('Service Worker not supported.');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


