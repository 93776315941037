import React, { useEffect, useContext } from 'react';
import { AppContext } from '../contexts/context';

// Components
import UnlockDialog from './UnlockDialog'
import DevDialog from './DevDialog'
import DialogComingSoon from './DialogComingSoon'

// Containers

// Material UI

import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu'
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ShareIcon from '@mui/icons-material/Share';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ClearIcon from '@mui/icons-material/Clear';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { createStyles, makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';



const useStyles = makeStyles((theme) =>
  createStyles({
    buttons: {
      color: 'white'
    },
    menuButton: {
      //marginRight: theme.spacing(2),
      marginRight: 20,
    },
    title: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: 'white'
    },
  }),
);

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}


const TopAppBar = (props) => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickShare = () =>{
    dispatch({type: 'app/ui/dialog/default/show', value: 'share'});
  }
  const handleClickReset = () =>{
    dispatch({type: 'ndef/control/resetChanges'});
  }
  const handleClickClear = () =>{
    dispatch({type: 'ndef/control/setForNewRead'});
  }

  const handleParamLock = () =>{
    if(state.settingAuthenticated===true){
      dispatch({type: 'app/settings/userAuthorized', value: false});
    }else{
      dispatch({type: 'app/ui/dialog/default/show', value: 'unlock'});
    }
  }

  const handleClickDevMode = () =>{
    dispatch({type: 'app/ui/dialog/default/show', value: 'devmode'});
  }

  useEffect(() => {
  }, [state.uiDialog]);

  return (
    <>
    <div className={classes.root}>
      <HideOnScroll {...props}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={()=>props.onToggle()}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {!state.nfcSchema.$id?state.appManifestName:state.nfcSchema.$id.split("/")[2]+" NFC Data"}
          </Typography>
          <UnlockDialog/>
          {state.appEnv === 'dev'
            ? <IconButton
                onClick={handleClickDevMode}
                aria-label="devMode"
                className={classes.buttons}
              >
                <Tooltip title="Developer Settings" aria-label="devMode">
                  <DeveloperModeIcon sx={{color:"#fff"}}/>
                </Tooltip>
              </IconButton>
            : <div></div>
          }
          {'CP' in state.nfcDataCP && (
            <Box onClick={()=>handleParamLock()} marginX={1} justifyContent={"center"} alignContent={"center"} paddingTop={0.5} aria-label="unlock">
              <Tooltip title="Unlock protected data" aria-label="unlock-tip">
                {state.settingAuthenticated===false?<LockOutlinedIcon sx={{color:"#fff", fontSize:26}}/>:<LockOpenOutlinedIcon sx={{color:"#fff", fontSize:26}}/>}
              </Tooltip>
            </Box>)
          }
          {'$id' in state.nfcSchema
            ?
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <Tooltip title="more" aria-label="more"></Tooltip>
                <MoreVertIcon sx={{color:"#fff"}}/>
              </IconButton>
            : <div></div>
          }
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                borderRadius: 5,
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleClickShare}>
              <ListItemIcon>
                <ShareIcon fontSize="small" />
              </ListItemIcon>
              Share configuration
            </MenuItem>
            <MenuItem onClick={handleClickReset}>
              <ListItemIcon>
                <RestartAltIcon fontSize="small" />
              </ListItemIcon>
              Revert changes
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClickClear}>
              <ListItemIcon>
                <ClearIcon fontSize="small" />
              </ListItemIcon>
              Clear data
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      </HideOnScroll>
      <Toolbar />
      {/* {state.uiDialog === 'share' && <DataExport/>} */}
      {state.uiDialog === 'share' && <DialogComingSoon/>}
      {state.uiDialog === 'devmode' && <DevDialog/>}
    </div>
    </>
  );
}

export default TopAppBar;
