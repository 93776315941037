// Context
import React, { useMemo, useState, useEffect, useContext } from 'react';

// Context
import { AppContext } from '../contexts/context';

// Material UI

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

// Firebase
// Todo(hoffmannm): move it to ServiceWorker?
// import { storage } from "./Firebase"
// import { ref, getDownloadURL } from "firebase/storage";

const useStyles = makeStyles((theme) => ({
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-100px',
    width: '200px',
    height: '100px',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
}));

// https://css-tricks.com/snippets/javascript/lighten-darken-color/
function LightenDarkenColor(col, amt) {

  var usePound = false;

  if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}

export const ThemeController = ({ children }) => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);
  const [ loading, setLoading ] = useState(true);
  const [ serviceWorkerFinished, setServiceWorkerFinished ] = useState(false);
  const [ manifestLoaded, setManifestLoaded ] = useState(false);
  const [ manifestLink, setManifestLink ] = useState('/kde.webmanifest');
  const [ loadingState, setLoadingState ] = useState('...');

  var wlValue = localStorage.getItem("wlValue");
  if (!wlValue) {
    console.warn(`ThemeController | Failed laoding wlValue from local storage! Using default value: kde`)
    wlValue = 'kde';
  }
  // const myURL = new URL(window.location.href);
  // var searchParams = new URLSearchParams(myURL.search);

  var primaryLight = LightenDarkenColor(state.appThemeTC1, -40);
  var primaryDark = LightenDarkenColor(state.appThemeTC1, 40);
  var secondaryLight = LightenDarkenColor(state.appThemeTC2, -40);
  var secondaryDark = LightenDarkenColor(state.appThemeTC2, 40);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            light: primaryLight,
            main: state.appThemeTC1,
            dark: primaryDark
          },
          secondary: {
            light: secondaryLight,
            main: state.appThemeTC2,
            dark: secondaryDark
          },
        },
        overrides: {
          MuiCard: {
            root: {
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              marginBottom: 0,
            }
          },
        },
        components: {
          MuiFormControl: {
            styleOverrides: {
              root: {
                marginBottom: 15,
              },
            }
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                fontWeight: 500,
              },
            }
          },
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                fontStyle: 'italic'
              }
            }
          }
        },
        spacing: 8,
      }),
    [state.appThemeTC1, state.appThemeTC2],
  );

  const appCycleCheck = () => {
    if ("serviceWorker" in navigator) {
      setLoadingState("Service Worker ...");
      navigator.serviceWorker.ready.then((registration) => {
        console.log(`ThemeController | appCycleCheck: A service worker is active:`, registration.active);
        setServiceWorkerFinished(true);
        // At this point, you can call methods that require an active
        // service worker, like registration.pushManager.subscribe()
      });
    } else {
      console.error("ThemeController | appCycleCheck: Service workers are not supported.");
      setLoadingState("Service Worker failed!");
    }

    // var manifestSelection = document.querySelector('#manifest-selection');
    window.addEventListener('load', function() {
      console.info("ThemeController | appCycleCheck: everything is finished");
      setManifestLoaded(true);
    });
  }

  const getManifestData=(href)=>{
    fetch(href, {
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(function(response){
      return response.json();
    }).then(function(myJson) {
      console.log('ThemeController | manifestData:', myJson);
      dispatch({type: 'app/theme/name/updated', value: myJson.name});
      dispatch({type: 'app/theme/shortName/updated', value: myJson.short_name});
      console.debug("ThemeController | loading done")
      setLoading(false);
    });
  }

  const getThemeData=(href)=>{
    fetch(href, {
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }).then(function(response){
      return response.json();
    }).then(function(myJson) {
      console.log('ThemeController | themeData:', myJson);
      dispatch({type: 'app/theme/tc1/updated', value: myJson.TC1});
      dispatch({type: 'app/theme/tc2/updated', value: myJson.TC2});
      dispatch({type: 'app/theme/th1/updated', value: myJson.TH1});
      dispatch({type: 'app/theme/th2/updated', value: myJson.TH2});
    });
  }

  useEffect(() => {
    appCycleCheck()
    if (serviceWorkerFinished) {
      setLoadingState("Theme ...");
      var href = `image/kde/theme.json`
      console.debug('ThemeController | wlValue:', wlValue);
      if (wlValue) {
        href = `image/${wlValue}/theme.json`
        dispatch({type: 'app/theme/logoUrl/updated', value: `image/${wlValue}/logo.png`});
        setManifestLink(`/${wlValue}.webmanifest`);
      }
      getThemeData(href);


      if (manifestLoaded) {
        if (manifestLink !== 'notdefined') {
          console.log("ThemeController | manifestLink:", manifestLink);
          getManifestData(manifestLink);
        } else {
          console.error('No href for webmanifest found!')
        }
      }
    }
  }, [manifestLink, manifestLoaded, serviceWorkerFinished]);

  if (loading === true) {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.loading}>
          <CircularProgress color="secondary" />
          <Typography sx={{
            marginTop: 2,
          }}>
            Loading {loadingState}
          </Typography>
        </div>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <>
          { children }
        </>
      </ThemeProvider>
    );
  }
}

