
import { useContext } from 'react';
import { AppContext } from '../contexts/context';
// Tools
import { updatedDiff } from 'deep-object-diff';

// ## JS Functions
export const getTimeString = () => {
  // Zeit als String in der Form "14:55" erstellen
  const nowTime = new Date();
  const hour = nowTime.getHours().toString().padStart(2, '0');
  const minute = nowTime.getMinutes().toString().padStart(2, '0');
  const seconds = nowTime.getSeconds().toString().padStart(2, '0');
  const timeString = `${hour}:${minute}:${seconds}`;
  return timeString;
}

// ## React Hooks
export const useCheckImportedData = () => {
  const [ state, dispatch ] = useContext(AppContext);
  let result = false;

  if ((!Object.keys(state.originP).length && Object.keys(state.nfcDataP).length)
  || (!Object.keys(state.originPR).length && Object.keys(state.nfcDataPR).length)
  || (!Object.keys(state.originPP).length && Object.keys(state.nfcDataPP).length)
  ) {
    result = true;
  }

  return result;
}

export const useCountDataKeys = () => {
  const [ state, dispatch ] = useContext(AppContext);

  let result = 0;
  var updatedP = {};
  var updatedPR = {};
  var updatedPP = {};

  if (useCheckImportedData()) {
    if (Object.keys(state.nfcDataP).length) {
      result += Object.keys(state.nfcDataP.P).length;
    }
    if (Object.keys(state.nfcDataPR).length) {
      result += Object.keys(state.nfcDataPR.PR).length;
    }
    if (Object.keys(state.nfcDataPP).length) {
      result += Object.keys(state.nfcDataPP.PP).length;
    }
  } else {
    updatedP = updatedDiff(state.originP, state.nfcDataP)
    updatedPR = updatedDiff(state.originPR, state.nfcDataPR)
    updatedPP = updatedDiff(state.originPP, state.nfcDataPP)

    if (Object.keys(updatedP).length) {
      result += Object.keys(updatedP.P).length;
    }
    if (Object.keys(updatedPR).length) {
      result += Object.keys(updatedPR.PR).length;
    }
    if (Object.keys(updatedPP).length) {
      result += Object.keys(updatedPP.PP).length;
    }
  }
  return result;
}

export const useGetLogo = () => {
  const [ state, dispatch ] = useContext(AppContext);
  return state.appThemeLogoUrl;
}

export const useTimeString = () => {

  return getTimeString();
}
