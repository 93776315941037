import React, { useState, useEffect, useContext} from 'react';
import { AppContext } from '../contexts/context';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import EngineeringIcon from '@mui/icons-material/Engineering';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogComingSoon = props => {
  const [ state, dispatch ] = useContext(AppContext);
  const [ open, setOpen ] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (state.uiDialog === 'share') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.uiDialog]);

  const handleClose = () => {
    dispatch({type: 'app/ui/dialog/default/close'});
    setOpen(false)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-share">
        <DialogTitle id="form-dialog-share" onClose={handleClose} >
          Coming soon
        </DialogTitle>
        <DialogContent sx={{display:"flex", flexDirection:"column", paddingY:1, alignItems:"center", justifyContent:"center",}}>
          <Avatar
            variant="square"
            sx={{
              bgcolor: theme.palette.primary.main,
              width: 90,
              height: 90,
              margin: 2,
              }}
          >
            <EngineeringIcon sx={{ fontSize:70}}/>
          </Avatar>
            <Typography gutterBottom>
              This feature is currently being implemented and will be available shortly.
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogComingSoon;
