import React, { useEffect, useContext } from 'react';

// Reducer
import { AppContext } from '../contexts/context';

// containers
import { recordId } from './RecordData'

// Firebase
import { storage } from "./Firebase"
import { ref, getDownloadURL } from "firebase/storage";

const DataImport = () => {
  // global context
  console.debug("DataImport: started")
  const [ state, dispatch ] = useContext(AppContext);

  const myURL = new URL(window.location.href);
  var searchParams = new URLSearchParams(myURL.search);

  // Firebase storage reference
  const storageRef = ref(storage);
  console.debug("DataImport: storageRef", storageRef)

  var queryCheck = true;

  if (myURL.search.length <= 3) {
    queryCheck = false;
  }

  const importDataByKeys = () => {
    const decoder = new TextDecoder('utf8');

    var qsUint8Array = null;
    var qsData = null;

    async function fetchDataAsync(url) {
      const response = await fetch(url);
      const result = await response.json();
      console.debug("Data downloaded ... ");
      console.debug("Data: ", result);
      console.debug("JSON: ", JSON.stringify(result));
      dispatch({type: 'data/S/updated', value: result});
    }

    for (var qsKey of searchParams.keys()) {
      var qsValue = searchParams.get(qsKey);

      if (qsKey in recordId) {
        console.debug(`DataImport: query parameter found ${qsKey}:${qsValue}`);
        if (qsValue.length > 0) {
          if (qsKey === 'S') {
            // Process schema
            // TODO: Make a semVer check.
            console.debug(`Firebase: Download started ... `);
            getDownloadURL(ref(storageRef, `${qsValue}/schemaDev.json`))
            .then((url) => {
              fetchDataAsync(url);
            })
            .catch((error) => {
              console.error("Firebase: Download error: ", error)
              dispatch({
                type: 'app/ui/dialog/error',
                title: `Import Schema Error`,
                text: `Could not find file under the following path: '${qsValue}'`
              });
            });
          } else {
            // Process values and parameters
            if (qsValue.includes(",")) {
              qsUint8Array = Uint8Array.from(qsValue.split(','));
              qsData = JSON.parse(decoder.decode(qsUint8Array));
              console.debug(`DataImport - ${qsKey} set from query string: ${qsData}`);
              dispatch({type: `data/${qsKey}/updated`, value: qsData});
            } else {
              dispatch({
                type: 'app/ui/dialog/error',
                title: `Import Error`,
                text: `Key has unknown data format: '${qsKey}'`
              });
            }
          }
        }
      } else {
        console.debug(`DataImport: unknown query parameter found ${qsKey}:${qsValue}`);
      }
    }
  }

  useEffect(() => {
    if (queryCheck) {
      importDataByKeys();
    }
  }, [ queryCheck ]);

  return (
    <div>
    </div>
  );

}

export default DataImport;
