import  React, { useContext, useState, forwardRef} from 'react';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { AppContext } from '../contexts/context';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppImprintDialog(props) {
  const [open, setOpen] = useState(true);
  const [ state, dispatch ] = useContext(AppContext);
  const theme = useTheme();

  const handleClose = () => {
    dispatch({type: 'app/ui/menu/dialog/default/close'});
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box sx={{ position: 'relative', backgroundColor:"#fff", mb:2 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon sx={{color:'#3b3b3b'}}/>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color:"#3b3b3b" }} variant="h6" component="div">
              Imprint
            </Typography>
          </Toolbar>
        </Box>
        <Box sx={{paddingInline:2, paddingBottom:2}}> 

        <List sx={{ width: '100%', maxWidth: 450, }}>
            <ListItem alignItems="flex-start" sx={{flexDirection:"column"}}>
              <Typography variant='subtitle2' sx={{color:state.appThemeTC1}}>
              Seat of the company
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              KD Elektroniksysteme GmbH <br/>Ahornweg 9<br/>39261 Zerbst
              </Typography>
            </ListItem>
            <ListItem alignItems="flex-start" sx={{flexDirection:"column"}}>
              <Typography variant='subtitle2' sx={{color:state.appThemeTC1}}>
              Managing Directors
              </Typography>
              <Typography variant='body2'>
              Dipl.-Ing. Ralf Kleinodt
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              M.Sc. MBA Robert Rathmann
              </Typography>
            </ListItem>
            <ListItem alignItems="flex-start" sx={{flexDirection:"column"}}>
              <Typography variant='subtitle2' sx={{color:state.appThemeTC1}}>
              Commercial Register
              </Typography>
              <Typography variant='body2'>
              Dipl.-Ing. Ralf Kleinodt
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              HRB 16323 <br/>Amtsgericht Stendal
              </Typography>
            </ListItem>
            <ListItem alignItems="flex-start" sx={{flexDirection:"column"}}>
              <Typography variant='subtitle2' sx={{color:state.appThemeTC1}}>
              VAT
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              DE 218448129
              </Typography>
            </ListItem>
            <ListItem alignItems="flex-start" sx={{flexDirection:"column"}}>
              <Typography variant='subtitle2' sx={{color:state.appThemeTC1}}>
              WEEE-Reg.-No.
              </Typography>
              <Typography variant='body2' sx={{mb:1}}>
              DE 78814686
              </Typography>
            </ListItem>              
          </List>  
        </Box>
      </Dialog>
    </div>
  );
}