import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../contexts/context';


import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import DefaultValueIcon from '@mui/icons-material/Replay';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { createStyles, makeStyles } from '@mui/styles';
import ResetIcon from '@mui/icons-material/RotateLeft';
import CloseIcon from '@mui/icons-material/Close';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/* const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}); */

/* const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions); */

export default function ErrorDialog() {
  const [ state, dispatch ] = useContext(AppContext);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    dispatch({
      type: 'app/ui/dialog/error',
      title: null,
      text: null
    });
  };

  useEffect(() => {
    if (state.errorText || state.errorTitle) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.errorText, state.errorTitle]);

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="form-dialog-error" open={open}>
        <DialogTitle id="form-dialog-error" onClose={handleClose}>
        {state.errorTitle !== null
          ? state.errorTitle
          : 'Error'
        }
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {state.errorText !== null
              ? state.errorText
              : 'Unknown Error'
            }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
